

.maincard {
  display: inline-block;
  margin-bottom: 25px;
  maxWidth: 150;
}



.maincard-flag {

  font-size: 150px;
}

.root {
  flexgrow: 1;
}

.cQjXmc .AISummaryContent__SummaryTextStyle-sc-x9icjp-1, .cQjXmc .AISummaryContent__SummaryListStyle-sc-x9icjp-5 {
    line-height: 1.4;
    font-size: 16px;
    color: black!important;
}

.DZBVr  {
    line-height: 1.32;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    color: grey!important;
}

.tskFp  {
    line-height: 1.32;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    color: grey!important;
}

.cvBsnK {
    line-height: 1.32;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    color: grey!important;
}

.jeSnNy {
    position: relative;
    display: block;
    padding: 0px;
    border: none;
    outline: none;
    border-radius: 50%;
    cursor: pointer;
    fill: grey!important;

}

element.style {
    width: 32px;
    height: 32px;
    background-color: rgba(255, 255, 255, 0.5);
    fill: black!important;
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px 0px; */
}
.LvweT .AISummaryContent__SummaryTextStyle-sc-12d8ue6-1, .LvweT .AISummaryContent__SummaryListStyle-sc-12d8ue6-5
 {
    line-height: 1.32;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    color: grey!important;
}

.ewhcbU {
    width: 100%;
    line-height: 1.4;
      color: black!important;
    font-size: 16px;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

.img-logo {
  width: 20px;
}

.Intro1 {
  color: gray;
  padding-left: 30px;
}

.Button {
  margin-left: 30px;
}

.field {
  width: 100%;
  height: 56px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s all;
}
.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}
.radiogroup {
  text-align: center;
  position: relative;
  float: center;
}


.PhoneInputInput {
  width: 100%  !important;;
  max-width: 1000px  !important;
  font-size: inherit !important;
  height: 55px;
  border: 1px solid #c5c5c5 !important;
  border-radius: 3px;
  background: none;
}

input.PhoneInputInput:hover {

  border: 1px solid black !important;
}

input.PhoneInputInput {
  width: 100%  !important;;
  font-size: inherit !important;
  height: 55px;
  border: 1px solid #c5c5c5 !important;
  border-radius: 3px;
  background: none;
}

input.PhoneInputInput::placeholder {
    position: relative;
    left: 10px;
}



a:link { text-decoration: none; }

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }

a.MuiTypography-root.MuiTypography-caption.css-1xpa00e-MuiTypography-root {
    font-size: inherit !important;
    color: inherit !important;
}

a.MuiTypography-root.MuiTypography-subtitle1.css-1bw8puw-MuiTypography-root {
    font-size: inherit !important;
    font-family: inherit !important;
}
a.MuiTypography-root.MuiTypography-h2.css-1po5mg-MuiTypography-root {
    font-family: inherit !important;
}
a.MuiTypography-root.MuiTypography-subtitle2.css-5c8f8-MuiTypography-root {
    font-family: inherit !important;
}
a.MuiTypography-root.MuiTypography-subtitle1.css-1vk9acc-MuiTypography-root {
    font-family: inherit !important;
    font-size: inherit !important;
}


.datepicker {
    width: 100%;
}
.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}



.es-ai-summary-list-item-text  {

  color: black;
}

.es-review-content-text {

  color: black;
}

.es-review-author-name {

    color: black;
}
.es-review-content-control-open {
  color: black;
}

.es-review-info-date {
   color: black;
}

.customDatePickerWidth,
    .customDatePickerWidth > div.react-datepicker-wrapper,
    .customDatePickerWidth > div > div.react-datepicker__input-container
    .customDatePickerWidth > div > div.react-datepicker__input-container input {
       width: 100%;
       height: 100%;
    }
    .react-datepicker__input-container  {
        width: inherit;
        height: inherit;
      }
      .react-datepicker__input-container input {
        width: inherit;
        height: 100%;
      }

      .react-datepicker-wrapper {
        width: 100%;
      }

      .rating {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      }
      .rating > input{
       display:none;
      }
      .rating > label {
      position: relative;
      width: 1.1em;
      font-size: 45px;
      color: #FFD700;
      cursor: pointer;
      opacity: 1 !important;

      }
      .rating > label::before{
      content: “\2605”;
      position: absolute;
      opacity: 1 !important;
      }


.floating-button {
  position: fixed;
   bottom: 20px;
   left: 51%;
   transform: translateX(-50%);
   z-index: 99999999;
   width: 190px !important;
   padding: 10px 0px;
   background-color: #007bff;
   color: white;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   font-size: 14px;
   box-shadow: 2px 2px 5px black;
   -webkit-box-shadow: 2px 2px 5px black;
   letter-spacing: -0.02rem;
   -moz-box-shadow: 2px 2px 5px black;
}

.floating-button:hover {
    background-color: #0056b3;
}

.MuiPaper-root {
    cursor: pointer;
}

.logo-govis {
    cursor: pointer;
}
